/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import successPayment from "./assets/payment_success.png";
import successPaymentSM from "./assets/mob_1.png";
import megaphone from "./assets/megaphone.png";
import greenCheck from "./assets/greenCheck.png";
import catalyst from "./assets/catalyst_logo.png";
import PrimaryLogo from "./assets/Primary_Logo.png";
import "./SuccessPayment.css";
import { makeStyles } from "@material-ui/core";
import { useTheme, useMediaQuery } from "@material-ui/core";

import ShareLink from "./ShareLink";
import { useState } from "react";
import Loader from "./Loader/Loader";
import TagManager from "react-gtm-module";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Poppins",
    height: "100vh",
  },
  homeLink: {
    textDecoration: "none",
    color: "#FF712D",
    textTransform: "uppercase",
    fontWeight: "bold",
    "&:hover": {
      color: "#FF712D",
    },
  },
  container: {
    position: "relative",
    marginTop: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Poppins",
    color: "#555555",
  },
  futureInnovators: {
    position: "absolute",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#FFEDE0",
    width: "80%",
    zIndex: 2,
    borderRadius: "12px",
    top: "-15px",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  referral: {
    border: "1px solid #FFE1CC",
    boxShadow: "0px 1px 8px rgba(129, 11, 11, 0.08)",
    borderRadius: "12px",
    zIndex: 1,
    padding: "60px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 20px 20px 20px",
    },
  },
  share: {
    width: "100%",
  },
  highlight: {
    color: "#0D6EFD",
    fontWeight: "bold",
  },
  megaphone: {
    [theme.breakpoints.up("sm")]: {
      marginRight: "10px",
    },
  },
  cyLogo: {
    background: "#FCFCFC",
    boxhadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
  },
  catalystColor: {
    color: "#4917B0",
    fontWeight: "bold",
  }
}));
const SuccessPayment = () => {
  const { transactionId } = useParams();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const [referenceNumber, setReferenceNumber] = useState();
  const [referralLink, setReferralLink] = useState();
  const { REACT_APP_URL } = process.env;
  const [loading, setLoading] = useState(false);
  const [parentPhone, setParentPhone] = useState();
  const [businessUnitId, setBusinessUnitId] = useState(1);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${REACT_APP_URL}/checkPayment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ paymentId: transactionId }),
      });
      if (!response.ok) {
        throw new Error("Something went Wrong");
      }

      const data = await response.json();
      setLoading(false);
      setBusinessUnitId(data?.businessUnitId);
      setReferralLink(data?.referralLink);
      setReferenceNumber(data?.referenceNumber);
      setParentPhone(data?.parentContact);
      successPageGTM(data?.parentContact);
    } catch (error) {
      setLoading(false);
    }
  };
  const successPageGTM = (parentContact) => {
    const tagManagerArgs = {
      gtmId: "GTM-TH4RWGG",
      dataLayer: {
        event: "codeyoung_site",
        event_category: "codeyoung_site",
        event_action: "payment_success",
        event_label: parentContact,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    TagManager.initialize(tagManagerArgs);
  };
  useEffect(() => {
    getData();
  }, []);
  if (loading) return <Loader />;
  return (
    <>
      {isSmall && (
        <div className={classes.cyLogo}>
          <img
            src={businessUnitId == 2 ? catalyst : PrimaryLogo }
            className="d-block mx-auto mt-5"
            width="150"
            height="50"
            alt="Logo"
          />
        </div>
      )}
      <div className={classes.root}>
        <div className="centerIt">
          {" "}
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-6 offset-lg-3 col-10 offset-1 text-center mx-auto">
              <img
                src={isSmall ? successPaymentSM : successPayment}
                className="d-block mx-auto"
                alt="Payment Success"
              />
              <h3 style={{marginTop:isSmall?"10px":"0px"}}>Payment Successful</h3>
              {referenceNumber && (
                <h6 style={{ color: "#999999" }}>
                  Payment Reference Number:{" "}
                  <span style={{ color: "#555555" }}>{referenceNumber}</span>
                </h6>
              )}
              <div>
                <a
                  href="https://www.codeyoung.com/"
                  target="_blank"
                  className={businessUnitId == 2 ? classes.catalystColor : classes.homeLink }
                >
                  <u style={{ textDecoration: "none" }}>Go To Home</u>
                </a>
              </div>
              <div className={classes.container}>
                <div className={classes.futureInnovators}>
                  <img src={megaphone} className={classes.megaphone} />
                  <span>Help Build Future Innovators</span>
                </div>
                <div className={classes.referral}>
                  <div>
                    Know someone like you who would wish to make their kids
                    amazing coders! Refer them today!
                  </div>
                  <ShareLink
                    referralLink={referralLink}
                    parentPhone={parentPhone}
                  />
                  <div style={{ marginTop: "10px" }}>
                    What more? Earn{" "}
                    <span className={classes.highlight}>$75</span> for every
                    successful referral too! Could it be any better?
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    For more details, view our{" "}
                    <a
                      className={businessUnitId == 2 ? classes.catalystColor : classes.homeLink }
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open(
                          "https://cy-assets.s3.ap-south-1.amazonaws.com/referral-policy+/referral_policy_oct_23.pdf"
                        )
                      }
                    >
                      referral policy
                    </a>
                  </div>
                </div>
              </div>
              {!isSmall && (
                <img
                src={businessUnitId == 2 ? catalyst : PrimaryLogo }
                  className="d-block mx-auto mt-5"
                  width="150"
                  height="50"
                  alt="Logo"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessPayment;
