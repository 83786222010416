import React, { useEffect, useState } from "react";
import failedPayment from "./assets/Frame 68.png";
import failedPaymentSM from "./assets/mob_2.png";
import replay from "./assets/replay.png";
import PrimaryLogo from "./assets/Primary_Logo.png";
import "./FailedPayment.css";
import catalyst from "./assets/catalyst_logo.png";
import PayPal from "./PayPal";
import { makeStyles } from "@material-ui/core";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import TagManager from "react-gtm-module";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Poppins",
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  failureText: {
    color: "#555555",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  btn: {
    background: "#FF712D",
    borderRadius: "8px",
    color: "white",
    padding: "10px",
    border: "none",
    marginTop: "10px",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "16px",
  },
  cyLogo: {
    background: "#FCFCFC",
    boxhadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
  },
  catalystButton: {
    background: "#4917B0",
    borderRadius: "8px",
    color: "white",
    padding: "10px",
    border: "none",
    marginTop: "10px",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "16px",
  },
}));
const FailedPayment = ({ businessUnitId, parentPhone }) => {
  const classes = useStyles();
  const { id } = useParams();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();
  const FailurePageGTM = () => {
    const tagManagerArgs = {
      gtmId: "GTM-TH4RWGG",
      dataLayer: {
        event: "codeyoung_site",
        event_category: "codeyoung_site",
        event_action: "payment_failure",
        event_label: parentPhone,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    TagManager.initialize(tagManagerArgs);
  };
  useEffect(() => {
    FailurePageGTM();
  }, []);
  const retryPayment = () => {
   // logic for pushing back to checkout page
  };
  return (
    <>
      {isSmall && (
        <div className={classes.cyLogo}>
          <img
            src={businessUnitId == 2 ? catalyst : PrimaryLogo}
            className="d-block mx-auto mt-5"
            width="150"
            height="50"
            alt="Logo"
          />
        </div>
      )}
      <div className={classes.root}>
        <div className="centerIt">
          {" "}
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-6 offset-lg-3 col-10 offset-1 text-center mx-auto">
              <img
                src={isSmall ? failedPaymentSM : failedPayment}
                className="d-block mx-auto"
              />
              <h3 style={{ color: "#444444" }}>Payment Failed!</h3>
              <div className={classes.failureText}>
                Your transaction has failed due to some error. You can retry the
                payment below.
              </div>
              <button
                className={ businessUnitId == 2 ? classes.catalystButton : classes.btn }
                onClick={retryPayment}
              >
                Retry Payment
                <img
                  src={replay}
                  style={{ marginBottom: "5px", marginLeft: "5px" }}
                />
              </button>
              {!isSmall && (
                <img
                  src={businessUnitId == 2 ? catalyst : PrimaryLogo}
                  className="d-block mx-auto mt-5"
                  width="150"
                  height="50"
                  alt="Logo"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FailedPayment;
