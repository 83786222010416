const loadScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_PAYLOCAL_URL;
    script.setAttribute("data-display-mode", "drawer");
    script.setAttribute("data-cd-id", process.env.REACT_APP_PAYLOCAL_CID);
    script.defer = true;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const payglocal = async ({
  link,
  setError,
  setSuccess,
}) => {
  const res = await loadScript();
  if (!res) {
    setError(true);
    return;
  }
  window.PGPay.launchPayment({
    redirectUrl: link
  }, (data) => {
    if (data.status == "SENT_FOR_CAPTURE") setSuccess(true);
    else setError(true);

  })
};

export default payglocal;
