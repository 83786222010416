import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import facebook from "./assets/fb.png";
import linkedIn from "./assets/Frame 62.png";
import twitter from "./assets/Frame 63.png";
import whatsapp from "./assets/Frame 64.png";
import copy from "./assets/Frame 65.png";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";


import TagManager from "react-gtm-module";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    boxShadow: "1px 5px 10px rgba(0, 0, 0, 0.2)",
  },
}));

const ShareLink = ({ referralLink, parentPhone }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const shareClickedGTM = (channel) => {
    // url for react-share buttons must not be blank
    const tagManagerArgs = {
      gtmId: "GTM-TH4RWGG",
      dataLayer: {
        event: "codeyoung_site",
        event_category: "codeyoung_site",
        event_action: channel == "link" ? "link_copied" : "social_clicked",
        event_label: parentPhone,
        event_label1: channel == "link" ? "" : channel,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <React.Fragment>
     {referralLink && (
      <TextField
      style={{
        fontSize: 14,
        border: "1px solid #939393",
        marginTop: "10px",
        paddingLeft: "4px",
      }}
      className="w-100"
      value={referralLink}
      disabled
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            className="mb-2"
            onClick={() => {
              shareClickedGTM("link");
              navigator.clipboard.writeText(`${referralLink}`);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
          >
            {isSmall ? (
              <React.Fragment>
                <img src={copy} style={{ marginTop: "5px" }} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {copied ? (
                  "Copied"
                ) : (
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#FF712D",
                      fontWeight: "bold",
                      marginTop: "4px",
                    }}
                  >
                    Copy
                  </span>
                )}
              </React.Fragment>
            )}
          </InputAdornment>
        ),
      }}
    />
     )}
      {/* <div style={{ margin: "10px" }}>Or Share Using</div>
      <div>
        <FacebookShareButton
          onClick={() => {
            shareClickedGTM("facebook");
          }}
          round={true}
          url={`${referralLink}`}
          quote={`My child is taking Live classes from Codeyoung and I am inviting you to try out the same for your child with my reference. Use this link to Book a Free Trial Class with my referral and get up to 50% discount on registering for the classes! -  `}
        >
          <img src={facebook} alt="facebook share" className="mx-2" />
        </FacebookShareButton>
        <LinkedinShareButton
          onClick={() => {
            shareClickedGTM("linkedin");
          }}
          round={true}
          url={`${referralLink}`}
          title={`My child is taking Live classes from Codeyoung and I am inviting you to try out the same for your child with my reference. Use this link to Book a Free Trial Class with my referral and get up to 50% discount on registering for the classes! -  `}
        >
          <img src={linkedIn} alt="linkedIn share" className="mx-2" />
        </LinkedinShareButton>{" "}
        <TwitterShareButton
          onClick={() => {
            shareClickedGTM("twitter");
          }}
          url={`${referralLink}`}
          title={`My child is taking Live classes from Codeyoung and I am inviting you to try out the same for your child with my reference. Use this link to Book a Free Trial Class with my referral and get up to 50% discount on registering for the classes! -  `}
          description="askdlfjklasjdfkjdsf"
        >
          <img src={twitter} alt="twitter share" className="mx-2" />{" "}
        </TwitterShareButton>{" "}
        <WhatsappShareButton
          onClick={() => {
            shareClickedGTM("whatsapp");
          }}
          round={true}
          url={`${referralLink}`}
          title={`My child is taking Live classes from Codeyoung and I am inviting you to try out the same for your child with my reference. Use this link to Book a Free Trial Class with my referral and get up to 50% discount on registering for the classes! - `}
        >
          <img src={whatsapp} alt="whatsapp share" className="mx-2" />
        </WhatsappShareButton>
      </div> */}
    </React.Fragment>
  );
};

export default ShareLink;
