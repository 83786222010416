import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
import chat from "../src/assets/comm_chat.png";
import expired from "../src/assets/expired.png";
import logo1 from "../src/assets/logopiece1.png";
import logo2 from "../src/assets/logopiece2.png";
import CodeYoung from "../src/assets/codeyoung_logo_mobile.svg";

const { REACT_APP_URL } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "50px",
        marginBottom: "50px",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            paddingTop: "15px"
        },
    },
    doneHeading: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "28px",
        lineHeight: "42px",
        color: "#3D3D3D",
        marginTop: "45px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
            lineHeight: "30px",
            marginBottom: "12px"
        },
    },
    doneTxt: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#554E4E",
        textAlign: "center",
        margin: "12px",
        // width: "550px",
        [theme.breakpoints.down("xs")]: {
            width: "280px",
            marginBottom: "20px"
        },
    },
    btnContainer: {
        border: "1px solid #25D366",
        color: "#25D366",
        background: "#FFFFFF",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "21px",
        textAlign: "center",
        marginTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        "&:hover": {
            background: "#FFFFFF",
            color: "#25D366",
            border: "1px solid #25D366",
        },
        [theme.breakpoints.down("xs")]: {
            margin: "0px"
        },
    },
    submitBtn: {
        padding: "8px 30px",
        color: "white",
        width: "220px",
        border: "1px solid #FF712D",
        height: "44px",
        fontSize: "16px",
        background: "#FF712D",
        boxShadow: "0px 1px 2px #FBE2C6",
        borderRadius: "6px",
        fontFamily: "Poppins",
        marginTop: "10px",
        marginBottom: "70px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#FF712D",
            color: "white",
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: "50px"
        },
    },
    submitted: {
        color: "#007BFF",
        fontWeight: "700",
        marginTop: "10px",
        marginBottom: "90px",
    },
    logo1:{
        position: "absolute",
        top: theme.spacing(30),
        left: theme.spacing(10),
        [theme.breakpoints.down("xs")]: {
            top: theme.spacing(15),
            left: theme.spacing(5),
            width: "16px",
            height: "20px"
        },
    },
    logo2: {
        position: "absolute",
        bottom: theme.spacing(10),
        right: theme.spacing(10),
        [theme.breakpoints.down("xs")]: {
            top: theme.spacing(30),
            right: theme.spacing(3),
            width: "16px",
            height: "20px"
        },
    },
    expiredIcon: {
        width: "400px", 
        marginTop: "50px",
        [theme.breakpoints.down("xs")]: {
            width: "280px",
            marginTop: "55px"
        },
    },
    spacing:{
        [theme.breakpoints.down("xs")]: {
            height: "30px"
        },
    },
    codeyoungLogo:{
        width: "186px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "20px",
            width:"150px"
        },
    }
}));

const PaymentExpiry = ({ paymentDetails, orderDetails }) => {
    const classes = useStyles();
    const [success, setSuccess] = useState(false);

    const handleClick = async () => {
        try {
            const response = await fetch(`${REACT_APP_URL}/expirationTicket`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    orderId: paymentDetails.orderId,
                    amount: paymentDetails.amount,
                    type: paymentDetails.type,
                    currency: orderDetails[0].currencyId
                }),
            });
            if (!response.ok) {
                throw new Error("Something went Wrong");
            }
            setSuccess(true);
        } catch (err) {
            setSuccess(false);
            console.log('error occured while creating ticket: ', err);
        }
    }

    return (
        <div className={classes.root}>
            <img src={CodeYoung} alt="cy_logo" className={classes.codeyoungLogo} />
            <img src={expired} alt="expired" className={classes.expiredIcon} />
            <img src={logo1} alt="logo1" className={classes.logo1} />
            <img src={logo2} alt="logo2" className={classes.logo2} />
            <div className={classes.doneHeading}>Payment link expired</div>
            <div className={classes.doneTxt} >
                We're sorry, the payment link you are trying to access has expired.<br/>
                <div className={classes.spacing}></div>
                Please request a new payment link to proceed with your transaction.
            </div>
            {success ?
                <div className={classes.submitted}>Submitted</div>
                : <button
                    className={classes.submitBtn}
                    onClick={handleClick}
                >
                    REQUEST NEW LINK
                </button>}
            <div className={classes.doneTxt}>
                We are available on Whatsapp in case you have any queries or concerns
            </div>
            <Button
                className={classes.btnContainer}
                onClick={() => window.open("https://wa.me/918884459977")}
            >
                Begin chat
                <img src={chat} style={{ marginLeft: "5px" }} />
            </Button>
        </div>)
}
export default PaymentExpiry;
