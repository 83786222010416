import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import SuccessPayment from "./SuccessPayment";
import FailedPayment from "./FailedPayment";
import Loader from "./Loader/Loader";
import { useHistory } from "react-router-dom";
import {
  PayPalScriptProvider,
  PayPalButtons,
  PayPalMessages,
} from "@paypal/react-paypal-js";
import SideFrame from "../src/assets/side_frame.png";
import CodeYoung from "../src/assets/codeyoung_logo_mobile.svg";
import { makeStyles } from "@material-ui/core";
import { useTheme, useMediaQuery } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Row, Col, Container } from "react-bootstrap";
import ArrowUp from "./assets/arrow_up.png";
import ArrowDown from "./assets/arrow_down.png";
import ArrowDownCatalyst from "./assets/catalyst_arrow_down.png";
import ArrowUpCatalyst from "./assets/catalyst_arrow_up.png";
import Bullet from "./assets/bullet_desktop.png";
import catalystSideFrame from "./assets/catalyst_side_banner.png";
import catalyst from "./assets/catalyst_logo.png";
import Success from "./Success";
import PaymentExpiry from "./PaymentExpiry";
import moment from "moment";
import razorpayCheckout from "./Razorpay";
import payglocal from "./payglocal";

import {
  browserName,
  isMobile,
  isTablet,
  isDesktop,
} from "react-device-detect";

const {
  REACT_APP_URL,
  REACT_APP_STRIPE_KEY,
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_PAYPAL_PAYLATER_CLIENT_ID,
} = process.env;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "28px",
    color: "#444444",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "center",
    },
  },
  subTitle: {
    fontSize: "16px",
    color: "#555555",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  rightBox: {
    marginTop: "40px",
  },
  fieldBox: {
    background: "#FFFFFF",
    borderRadius: "10px",
    padding: "15px",
    marginTop: "20px",
  },
  fieldName: {
    color: "#999999",
    fontSize: "16px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  fieldValue: {
    color: "#444444",
    fontSize: "18px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  period: {
    fontWeight: "400",
  },
  convenienceFeesBox: {
    borderRadius: "4px",
    background: "#F6F4F4",
    textAlign: "center",
    color: "#999999",
    fontSize: "12px",
    paddingTop: "8px",
    paddingBottom: "8px",
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    fontFamily: "Poppins",
  },
  infoIcon: {
    marginBottom: "0.20em",
    marginRight: "0.25em",
  },
  refundPolicyBox: {
    boxShadow: "0px 1px 8px rgba(129, 11, 11, 0.08)",
    background: "#FFF",
    padding: "15px",
    borderRadius: "10px",
  },
  refundPolicyPoints: {
    paddingLeft: "5px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#555555",
  },
  checkoutButton: {
    background: "#FF712D",
    border: "none",
    width: "100%",
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: "600",
    borderRadius: "5px",
    padding: "7px",
  },
  subscriptionNoteBox: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  subscriptionNote: {
    color: "#5181FF",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "center",
  },
  checkboxContainer: {
    width: "100%",
    padding: "0px 4px",
    display: "flex",
    marginTop: "30px",
    marginBottom: "15px",
  },
  checkBoxText: {
    margin: "-2px 0px 0px 10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#555555",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      lineHeight: "15px",
    },
  },
  anchorTag: {
    color: "#555555",
  },
  checkmarkFieldText: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "5px",
    color: "#dc3545",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      lineHeight: "15px",
    },
  },
  catalystButton: {
    background: "#4917B0",
    border: "none",
    width: "100%",
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: "600",
    borderRadius: "5px",
    padding: "7px",
  },
  normalHr: {
    border: "1px solid #FFD6C2",
  },
  catalystHr: {
    border: "1px solid #D6C2FF",
  },
}));

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
const ProductDisplay = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [tokenStatus, setTokenStatus] = useState(false);
  const [payment, setPayment] = useState({});
  const [registrationDetails, setRegistrationDetails] = useState({}); //Used for Stripe Subs
  const [subscribedFor, setSubscribedFor] = useState(null);
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [vendor, setVendor] = useState("");
  const [paypalPlanId, setPaypalPlanId] = useState("");
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [subscriptionDuration, setSubscriptionDuration] = useState("");
  const [parentPhone, setParentPhone] = useState();
  const [robticsCourseId, setRoboticsCourseId] = useState("");
  const [refundPolicyPointList, setRefundPolicyPointList] = useState([]);
  const [policy, setPolicy] = useState();
  const [convenienceFees, setConvenienceFees] = useState(0);
  const [showClickCheckMark, setShowClickCheckMark] = useState(false);
  const [submit, setSubmit] = useState(true);
  const [expiry, setExpiry] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [browserInfo, setBrowserInfo] = useState({});
  const [businessUnitId, setBusinessUnitId] = useState(1);
  const [isFullpayment, setIsFullpayment] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const roundHalf = (num) => {
    return (Math.round(num * 2) / 2).toFixed(2);
  };

  const handleCheckboxChange = (e) => {
    setSubmit(e.target.checked);
    setShowClickCheckMark(false);
  };

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIpAddress(data.ip);
      })
      .catch((error) => console.log("Error fetching IP address:", error));

    const getBrowserDetails = () => {
      let browser = browserName ? { browserName } : { browserName: "Unknown" };
      if (isDesktop) {
        browser.deviceType = "Desktop";
      } else if (isTablet) {
        browser.deviceType = "Tablet";
      } else if (isMobile) {
        browser.deviceType = "Mobile";
      } else {
        browser.deviceType = "Unknown";
      }

      let userAgent = navigator.userAgent;
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browser.browserVersion = `Chrome v${userAgent.split("Chrome/")[1]}`;
      } else if (userAgent.match(/firefox|fxios/i)) {
        browser.browserVersion = `Firefox v${userAgent.split("Firefox/")[1]}`;
      } else if (userAgent.match(/safari/i)) {
        browser.browserVersion = `Safari v${userAgent.split("Safari/")[1]}`;
      } else if (userAgent.match(/edg/i)) {
        browser.browserVersion = `Edg v${userAgent.split("Edg/")[1]}`;
      } else {
        browser.version = "No Version detected";
      }
      return browser;
    };
    const browser = getBrowserDetails();
    setBrowserInfo(browser);
  }, []);

  useEffect(() => {
    const checkPaymentDone = async () => {
      setLoading(true);
      try {
        // in future if there is a custom url we can use thes api for setting the url from EpicenterApi
        const response = await fetch(`${REACT_APP_URL}/checkPayment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ paymentId: id }),
        });
        if (!response.ok) {
          throw new Error("Something went Wrong");
        }

        const data = await response.json();
        setBusinessUnitId(data?.businessUnitId);
        setTokenStatus(data?.tokenAmountStatus);
        setParentPhone(data?.parentContact);
        if (data?.subsPlanId) {
          setPaypalPlanId(data.subsPlanId);
        }
        if (data?.robticsCourseId) {
          setRoboticsCourseId(data.robticsCourseId);
        }
        if (data?.vendor) {
          setVendor(data.vendor);
        }
        if (!data.tokenAmountStatus) {
          setLoading(true);
          const response = await fetch(`${REACT_APP_URL}/get/payment/details`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ paymentId: id }),
          });
          if (!response.ok) {
            throw new Error("Something went Wrong");
          }
          const data = await response.json();
          setOrder(data.orderDetails);
          setPayment(data.paymentDetails);
          setRegistrationDetails(data.installmentDetails);
          setSubscribedFor(data.subscriptionPlan.period);
          setSubscriptionDuration(data.totalInstallments);
          setRefundPolicyPointList(data.refundPolicyPointList);
          setPolicy(data.policy);
          setConvenienceFees(data.convenienceFees);
          setIsFullpayment(data.isFullpayment);
          setLoading(false);

          const currentUTCTime = moment.utc();

          if (data?.paymentDetails?.type === "Token") {
            const duration = moment.duration(
              currentUTCTime.diff(data?.paymentDetails?.createdAt)
            );
            const isMoreThan15Days = duration.asDays() > 15;
            if (isMoreThan15Days) {
              setExpiry(true);
            }
          }
        }
        setLoading(false);
      } catch (e) {
        console.log("error: ", e);
        setLoading(false);
        setError(true);
      }
    };
    checkPaymentDone();
  }, [id]);

  useEffect(() => {
    if (success) {
      history.push(`/success/${id}`);
    }
  }, [history, id, subscribedFor, success]);

  const userConsent = async () => {
    try {
      const countryId = order[0].countryId;
      const masterOrderId = payment.orderId;
      const paymentId = payment.paymentId;

      const response = await fetch(`${REACT_APP_URL}/userConsent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          countryId,
          masterOrderId,
          paymentId,
          ipAddress,
          ...browserInfo,
        }),
      });
      if (!response.ok) {
        throw new Error("Unable to get user consent details.");
      }
      await response.json();
    } catch (err) {
      console.log("error while getting user info: ", err);
    }
  };

  const handleClick = async (e) => {

    if (!submit) {
      setShowClickCheckMark(true);
      return;
    }

    await userConsent();
    if (vendor === "stripe") {
      const stripe = await stripePromise;

      const response = await fetch(`${REACT_APP_URL}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          courseName: order[0].courseName,
          currency: order[0].currencyId,
          amount: payment.amount,
          id,
          CourseId: order[0].CourseId,
          robticsCourseId,
        }),
      });

      const session = await response.json();

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (vendor === "razorpay") {
      e.preventDefault();
      await razorpayCheckout({
        amount: Math.round(parseFloat(payment.amount) * 100),
        merchantOrderId: payment.merchantOrderId,
        currency: order[0].currencyId,
        subscribedFor,
        setError,
        setSuccess,
      });
      return;
    } else if (vendor === "payglocal") {
      e.preventDefault();
      const response = await fetch(`${REACT_APP_URL}/get/payglocal-link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ paymentId: id }),
      });
      const data = await response.json();
      const link = data.link;
      await payglocal({
        link,
        setError,
        setSuccess,
      });
    }
  };

  return error ? (
    <FailedPayment businessUnitId={businessUnitId} parentPhone={parentPhone} />
  ) : !tokenStatus ? (
    <div>
      {loading ? (
        <Loader />
      ) : expiry ? (
        <PaymentExpiry paymentDetails={payment} orderDetails={order} />
      ) : (
        <div style={{ margin: "0px", background: "#FFFBFA" }} className="row">
          <Container>
            <Row>
              {isSmall ? (
                <div
                  style={{
                    height: "72px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    background: "#FCFCFC",
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={businessUnitId == 2 ? catalyst : CodeYoung}
                    style={{ height: "30px" }}
                    alt="Codeyoung Logo"
                  />
                </div>
              ) : (
                <Col sm={4} style={{ padding: "0px" }}>
                  <div>
                    <img
                      src={businessUnitId == 2 ? catalystSideFrame : SideFrame}
                      style={{ height: "100vh" }}
                      alt="Side Frame"
                    />
                  </div>
                </Col>
              )}

              <Col sm={8} className={classes.rightBox}>
                <div style={{ background: "#FFFBFA", width: "100%" }}>
                  <div>
                    <p className={classes.title}>Your Order Details</p>
                  </div>
                  <div>
                    <p className={classes.subTitle}>
                      Please check the selected course and payment details
                      before checking out to pay
                    </p>
                  </div>
                  <Row>
                    <Col sm={6}>
                      <div className={classes.fieldBox}>
                        <span className={classes.fieldName}>PARENT NAME</span>
                        <br />
                        <span className={classes.fieldValue}>
                          {order[0]?.parentName}
                        </span>
                      </div>
                      {order.map((ord) => (
                        <div className={classes.fieldBox}>
                          <div style={{ marginBottom: "10px" }}>
                            <span className={classes.fieldName}>
                              CHILD NAME
                            </span>
                            <br />
                            <span className={classes.fieldValue}>
                              {ord?.studentName}
                            </span>
                          </div>
                          <span className={classes.fieldName}>COURSE NAME</span>
                          <br />
                          <span className={classes.fieldValue}>
                            {ord?.courseName}
                          </span>{" "}
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#444444",
                            }}
                          >
                            ({ord?.session} classes)
                          </span>
                        </div>
                      ))}
                    </Col>
                    <Col sm={6}>
                      <div className={classes.fieldBox}>
                        {subscribedFor &&
                          payment?.amount !== registrationDetails?.amount && (
                            <div
                              style={{
                                marginBottom: "15px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span className={classes.fieldName}>
                                REGISTRATION AMOUNT
                              </span>
                              <span className={classes.fieldValue}>
                                {" "}
                                {convenienceFees > 0
                                  ? `${order[0]?.currencySymbol} ${roundHalf(
                                      (payment?.amount * 100) /
                                        (100 + convenienceFees)
                                    )}`
                                  : `${order[0]?.currencySymbol} ${payment?.amount}`}
                              </span>
                            </div>
                          )}
                        {subscribedFor && (
                          <div
                            style={{
                              marginBottom: "15px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className={classes.fieldName}>
                              SUBSCRIPTION AMOUNT
                            </span>
                            <span className={classes.fieldValue}>
                              <span>
                                {convenienceFees > 0
                                  ? `${order[0]?.currencySymbol} ${roundHalf(
                                      (registrationDetails?.amount * 100) /
                                        (100 + convenienceFees)
                                    )}`
                                  : `${order[0]?.currencySymbol} ${registrationDetails?.amount}`}
                              </span>
                              <span className={classes.period}>
                                /
                                {subscribedFor === "monthly" && isSmall ? (
                                  <span>mo.</span>
                                ) : (
                                  subscribedFor === "monthly" && (
                                    <span>month</span>
                                  )
                                )}
                                {subscribedFor === "yearly" && isSmall ? (
                                  <span>yr.</span>
                                ) : (
                                  subscribedFor === "yearly" && (
                                    <span>year</span>
                                  )
                                )}
                                {subscribedFor === "weekly" && isSmall ? (
                                  <span>wk.</span>
                                ) : (
                                  subscribedFor === "weekly" && (
                                    <span>week</span>
                                  )
                                )}
                              </span>
                            </span>
                          </div>
                        )}

                        {subscribedFor && (
                          <div
                            style={{
                              marginBottom: "15px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className={classes.fieldName}>
                              SUBSCRIPTION DURATION
                            </span>
                            <span className={classes.fieldValue}>
                              {subscriptionDuration}{" "}
                              {subscribedFor === "monthly" && (
                                <span>month</span>
                              )}
                              {subscribedFor === "weekly" && <span>week</span>}
                              {subscribedFor === "yearly" && <span>year</span>}
                            </span>
                          </div>
                        )}

                        {!subscribedFor && (
                          <div
                            style={{
                              marginBottom: "25px",
                              marginTop: "25px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {payment?.type === "Token" ? (
                              <span className={classes.fieldName}>
                                {vendor === "paypal"
                                  ? "AMOUNT PAYABLE"
                                  : "REGISTRATION AMOUNT"}
                              </span>
                            ) : (
                              <span className={classes.fieldName}>
                                TOTAL AMOUNT
                              </span>
                            )}

                            <span className={classes.fieldValue}>
                              {convenienceFees > 0
                                ? `${order[0]?.currencySymbol} ${roundHalf(
                                    (payment?.amount * 100) /
                                      (100 + convenienceFees)
                                  )}`
                                : `${order[0]?.currencySymbol} ${payment?.amount}`}
                            </span>
                          </div>
                        )}

                        {convenienceFees > 0 && (
                          <div className={classes.convenienceFeesBox}>
                            <InfoOutlinedIcon
                              fontSize="inherit"
                              className={classes.infoIcon}
                            />
                            {subscribedFor
                              ? `${convenienceFees}% Convenience Fee added to all transactions`
                              : `${convenienceFees}% Convenience Fee is applicable`}
                          </div>
                        )}

                        <div className={classes.checkboxContainer}>
                          <input
                            type="checkbox"
                            id="checkbox"
                            value={submit}
                            defaultChecked={submit}
                            onChange={(e) => handleCheckboxChange(e)}
                            style={{ width: "18px", height: "18px" }}
                          />
                          <label
                            htmlFor="checkbox"
                            className={classes.checkBoxText}
                          >
                            By proceeding you are agreeing to Codeyoung’s{" "}
                            <a
                              href={policy?.terms || "https://codeyoung.com"}
                              className={classes.anchorTag}
                            >
                              terms
                            </a>{" "}
                            of use and{" "}
                            <a
                              href={
                                policy?.privacyPolicy || "https://codeyoung.com"
                              }
                              className={classes.anchorTag}
                            >
                              privacy policy
                            </a>
                          </label>
                        </div>

                        {vendor === "paypal" ? (
                          paypalPlanId ? (
                            <PayPal
                              plan_id={paypalPlanId}
                              currency={order[0].currencyId}
                              setError={setError}
                              setSuccess={setSuccess}
                              submit={submit}
                              order={order}
                              payment={payment}
                              browserInfo={browserInfo}
                              ipAddress={ipAddress}
                              isFullpayment={isFullpayment}
                            />
                          ) : (
                            <PayPal
                              payment_id={id}
                              currency={order[0].currencyId}
                              setError={setError}
                              setSuccess={setSuccess}
                              submit={submit}
                              order={order}
                              payment={payment}
                              browserInfo={browserInfo}
                              ipAddress={ipAddress}
                              isFullpayment={isFullpayment}
                            />
                          )
                        ) : (
                          <>
                            <button
                              type="button"
                              onClick={handleClick}
                              className={
                                businessUnitId == 2
                                  ? classes.catalystButton
                                  : classes.checkoutButton
                              }
                            >
                              {`Proceed to Pay ${order[0]?.currencySymbol}${payment?.amount}`}
                            </button>
                            {!subscribedFor && payment?.amount && (
                              <div style={{ marginTop: "30px" }}></div>
                            )}
                            {showClickCheckMark && (
                              <div className={classes.checkmarkFieldText}>
                                Please check the required field.
                              </div>
                            )}
                          </>
                        )}
                        {subscribedFor && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "20px",
                            }}
                          >
                            <div className={classes.subscriptionNoteBox}>
                              <p className={classes.subscriptionNote}>
                                (Subscription amount is auto debited every month
                                for the duration)
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      {order.length > 1 && (
                        <div
                          style={{ marginTop: "25px", marginBottom: "20px" }}
                        >
                          <div className={classes.refundPolicyBox}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#999999",
                                  }}
                                >
                                  REFUND POLICY
                                </span>
                              </div>
                              {isSmall ? (
                                <></>
                              ) : order.length > 1 ? (
                                <div style={{ width: "60%" }}>
                                  <hr
                                    className={
                                      businessUnitId == 2
                                        ? classes.catalystHr
                                        : classes.normalHr
                                    }
                                  />
                                </div>
                              ) : (
                                <div style={{ width: "70%" }}>
                                  <hr
                                    className={
                                      businessUnitId == 2
                                        ? classes.catalystHr
                                        : classes.normalHr
                                    }
                                  />
                                </div>
                              )}
                              <div
                                onClick={() =>
                                  setShowRefundPolicy(!showRefundPolicy)
                                }
                              >
                                {showRefundPolicy ? (
                                  <img
                                    src={
                                      businessUnitId == 2
                                        ? ArrowUpCatalyst
                                        : ArrowUp
                                    }
                                    alt="Arrow Up"
                                  />
                                ) : (
                                  <img
                                    src={
                                      businessUnitId == 2
                                        ? ArrowDownCatalyst
                                        : ArrowDown
                                    }
                                    alt="Arrow Down"
                                  />
                                )}
                              </div>
                            </div>
                            {showRefundPolicy &&
                              refundPolicyPointList.map((point, index) => (
                                <div style={{ paddingLeft: "15px" }}>
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      <img src={Bullet} alt="Bullet" />
                                    </div>
                                    <div>
                                      <span
                                        className={classes.refundPolicyPoints}
                                      >
                                        {point}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {order.length === 1 && (
                    <div style={{ marginTop: "25px", marginBottom: "20px" }}>
                      <div className={classes.refundPolicyBox}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "15px",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#999999",
                              }}
                            >
                              REFUND POLICY
                            </span>
                          </div>
                          {isSmall ? (
                            <></>
                          ) : (
                            <div style={{ width: "70%" }}>
                              <hr
                                className={
                                  businessUnitId == 2
                                    ? classes.catalystHr
                                    : classes.normalHr
                                }
                              />
                            </div>
                          )}
                          <div
                            onClick={() =>
                              setShowRefundPolicy(!showRefundPolicy)
                            }
                          >
                            {showRefundPolicy ? (
                              <img
                                src={
                                  businessUnitId == 2
                                    ? ArrowUpCatalyst
                                    : ArrowUp
                                }
                                alt="Arrow Up"
                              />
                            ) : (
                              <img
                                src={
                                  businessUnitId == 2
                                    ? ArrowDownCatalyst
                                    : ArrowDown
                                }
                                alt="Arrow Down"
                              />
                            )}
                          </div>
                        </div>
                        {showRefundPolicy &&
                          refundPolicyPointList.map((point) => (
                            <div style={{ paddingLeft: "15px" }}>
                              <div style={{ display: "flex" }}>
                                <div>
                                  <img src={Bullet} alt="Bullet" />
                                </div>
                                <div>
                                  <span className={classes.refundPolicyPoints}>
                                    {point}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  ) : (
    <SuccessPayment id={id} />
  );
};

function PayPal({
  plan_id,
  payment_id,
  currency,
  setError,
  setSuccess,
  submit,
  order,
  payment,
  browserInfo,
  ipAddress,
  isFullpayment,
}) {
  const initialOptions = {
    "client-id": REACT_APP_PAYPAL_CLIENT_ID,
    intent: plan_id ? "subscription" : "capture",
    vault: plan_id ? true : false,
    currency: `${currency}`,
  };

  const payLaterOption = {
    "client-id": REACT_APP_PAYPAL_PAYLATER_CLIENT_ID,
    components: ["messages", "buttons"],
    "enable-funding": "paylater",
    intent: "capture",
    vault: false,
    currency: `${currency}`,
  };

  const userConsent = async () => {
    try {
      const countryId = order[0].countryId;
      const masterOrderId = payment.orderId;
      const paymentId = payment.paymentId;

      const response = await fetch(`${REACT_APP_URL}/userConsent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          countryId,
          masterOrderId,
          paymentId,
          ipAddress,
          ...browserInfo,
        }),
      });
      if (!response.ok) {
        throw new Error("Unable to get user consent details.");
      }
      await response.json();
    } catch (err) {
      console.log("error while getting user info: ", err);
    }
  };

  return isFullpayment ? (
    <PayPalScriptProvider options={payLaterOption}>
      {order && order[0] && order[0].countryId == 2 && (
        <PayPalMessages
          style={{ layout: "text" }}
          amount={Number(payment.amount)}
          buyerCountry={"US"} // paylater is available in US
        />
      )}
      <PayPalButtons
        createOrder={async (data, actions) => {
          await userConsent();
          const orderData = await actions.order.create({
            application_context: {
              return_url: `${window.location.href}`,
              cancel_url: `${window.location.href}`,
              brand_name: "Codeyoung",
              locale: "en-US",
              shipping_preference: "NO_SHIPPING",
            },
            purchase_units: [
              {
                custom_id: payment_id,
                description: "Registration Amount",
                amount: {
                  currency_code: `${currency}`,
                  value: `${payment.amount}`,
                },
              },
            ],
          });
          return orderData;
        }}
        // Call your server to finalize the transaction
        onApprove={async (data, actions) => {
          const orderData = await actions.order.capture();

          // Three cases to handle:
          //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
          //   (2) Other non-recoverable errors -> Show a failure message
          //   (3) Successful transaction -> Show confirmation or thank you

          var errorDetail =
            Array.isArray(orderData.details) && orderData.details[0];
          if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
            return actions.restart();
          }

          if (errorDetail) {
            var msg = "Sorry, your transaction could not be processed.";
            if (errorDetail.description)
              msg += "\n\n" + errorDetail.description;
            if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
            console.error(msg);
            setError(true);
          }

          setSuccess(true);
        }}
        onError={(err) => {
          console.log("err", err);
          setError(true);
        }}
      />
    </PayPalScriptProvider>
  ) : (
    <PayPalScriptProvider options={initialOptions}>
      {plan_id ? (
        <PayPalButtons
          createSubscription={async (data, actions, err) => {
            await userConsent();
            const orderId = await actions.subscription.create({
              plan_id: plan_id, // Creates the subscription
            });
            return orderId;
          }}
          disabled={!submit}
          style={{
            label: "subscribe",
          }}
          onApprove={(data, actions) => {
            alert(
              "You have successfully created subscription " +
                data.subscriptionID
            ); // Optional message given to subscriber
            setSuccess(true);
          }}
          onError={(err) => {
            console.log("err", err);
            setError(true);
          }}
        />
      ) : (
        <PayPalButtons
          // Call your server to set up the transaction
          createOrder={async (data, actions) => {
            await userConsent();
            const orderData = await actions.order.create({
              application_context: {
                return_url: `${window.location.href}`,
                cancel_url: `${window.location.href}`,
                brand_name: "Codeyoung",
                locale: "en-US",
                shipping_preference: "NO_SHIPPING",
              },
              purchase_units: [
                {
                  custom_id: payment_id,
                  description: "Registration Amount",
                  amount: {
                    currency_code: `${currency}`,
                    value: `${payment.amount}`,
                  },
                },
              ],
            });
            return orderData;
          }}
          disabled={!submit}
          // Call your server to finalize the transaction
          onApprove={async (data, actions) => {
            const orderData = await actions.order.capture();

            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you

            var errorDetail =
              Array.isArray(orderData.details) && orderData.details[0];
            if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
              return actions.restart();
            }

            if (errorDetail) {
              var msg = "Sorry, your transaction could not be processed.";
              if (errorDetail.description)
                msg += "\n\n" + errorDetail.description;
              if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
              console.error(msg);
              setError(true);
            }

            setSuccess(true);
          }}
          onError={(err) => {
            console.log("err", err);
            setError(true);
          }}
        />
      )}
    </PayPalScriptProvider>
  );
}

const Message = ({ message, id }) => {
  return message === "success" ? <SuccessPayment id={id} /> : <FailedPayment />;
};

export default function App() {
  const [message, setMessage] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success") && query.get("id")) {
      setId(query.get("id"));
      setMessage("success");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return message ? (
    <Message message={message} id={id} />
  ) : (
    <Router>
      <Switch>
        <Route path="/:success/:transactionId">
          <Success />
        </Route>
        <Route exact path="/:id" component={ProductDisplay} />
      </Switch>
    </Router>
  );
}
