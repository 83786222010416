import logo from "./assets/codeyoung.png";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const razorpayCheckout = async ({
  amount,
  merchantOrderId,
  subscribedFor,
  currency,
  setError,
  setSuccess,
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    setError(true);
    return;
  }

  const order = subscribedFor
    ? { subscription_id: merchantOrderId }
    : { order_id: merchantOrderId };

  const details = subscribedFor
    ? {}
    : {
        amount: amount.toString(),
        currency: currency,
      };

  const options = {
    key: process.env.REACT_APP_RAZORPAY_KEY,
    name: "Codeyoung",
    description: "Codeyoung classes payment",
    image: { logo },
    ...order,
    ...details,
    handler: async function () {
      setSuccess(true);
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

export default razorpayCheckout;
